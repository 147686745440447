<template>
  <div class="login-container">
    <div class="flet">
      <div class="flets">
        <img src="../../assets/logo-white.png" alt>
        <div class="span">湖南和仲电子有限公司</div>
        <div class="middel">
          <span class="span1">基于蓝牙AOA高精度定位技术</span>
          <span class="span2">位置服务云平台</span>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="login">登录</div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-row>
          <el-col>
            <el-form-item style="margin: 10px 0;" prop="username">
              <div class="lable">用户名</div>
              <el-input v-model="form.username" placeholder="请输入用户名" />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item style="margin: 10px 0" prop="password">
              <div class="lable">密码</div>
              <el-input v-model="form.password" placeholder="请输入密码" show-password />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" block class="bottn" @click="handerSubmit()">登录</el-button>

    </div>
  </div>
</template>
<script>
import { login } from '@/api/admin/login'
export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, trigger: 'blur', message: '用户名不能为空' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '密码不能为空' }
        ]
      }
    }
  },
  methods: {
    handerSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          login(this.form)
            .then(res => {
              localStorage.setItem('token', res.data.token)
              localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
              this.$router.push({
                path: this.redirect || '/admin/home',
                query: this.otherQuery
              })
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.flet {
  background-image: url("../../assets/banner-login.png");
  background-size: 100% 100%;
  display: inline-block;
  height: 100%;
  flex: 1;
  img {
    width: 76px;
    margin: 10px;
    position: relative;
  }
  .flets {
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    .middel {
      .span1 {
        color: #ffffff;
        text-align: center;
        font-size: 33px;
        font-weight: bold;
        margin-top: 215px;
      }
      .span2 {
        color: #ffffff;
        text-align: center;
        font-size: 40px;
        margin-top: 47px;
        font-weight: bold;
      }
      span {
        display: block;
      }
    }
  }
  .span {
    display: inline-block;
    position: absolute;
    color: #ffff;
    font-size: 22px;
    font-weight: bold;
    top: 41px;
  }
}
.right {
  display: inline-block;
  width: 38%;
  background-color: #242e42;
  padding: 0 8%;
  .login {
    color: #ffffff;
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    padding-top: 200px;
  }
  .lable {
    color: #ffffff;
  }
  .bottn {
    width: 100%;
    margin-top: 40px;
    text-align: center;
    line-height: 23px;
  }
  .bottom {
    color: #ffffff;
    margin-top: 10px;
    display: flex;
    .apply {
    }
    .forget {
      margin-left: auto;
    }
  }
  .other {
    margin-top: 28px;
    font-size: 14px;
    .loginother {
      color: #ffffff;
    }
    .loginothers {
      margin-top: 5px;
      .alipay {
        margin-left: 12px;
      }
      .qq {
        margin-left: 12px;
      }
    }
  }
}
</style>
<style lang="scss">
.login-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;

  .el-input__inner {
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(255, 255, 255, 0.3);
       color: white;
    // border-bottom
  }
  .el-input__inner:focus {
    border-color: rgb(64, 158, 255);
  }
  // .el-input__inner::before{
  //   border: 1px solid rgba(255,255,255,0.3)
  //   // border-bottom
  // }
}
</style>
